<template>
  <el-card id="unmark">
    <header>
      <h1 style="font-weight:bold">取消标记</h1>
      <!-- <a href="http://47.109.70.167:9005/app.plist">旅途</a> -->
      <!-- <a
        href="itms-services:///?action=download-manifest&url=http://sjt-recording2.oss-cn-chengdu.aliyuncs.com/app.plist"
        class="button button-stripe"
        >旅途</a
      > -->
    </header>
    <hr>
    <main>
      <div class="content">
        <div class="link">
          <el-link type="primary" href="http://haomashensu.360.cn/index.html" target="_blank"
            style="font-size: 18px">360手机卫士</el-link>
          <span>或 &nbsp;拨打</span> 010-89180702
        </div>

        <div class="link">
          <el-link type="primary" href="http://www.dianhua.cn/appeal" target="_blank"
            style="font-size: 18px">电话邦</el-link>
          <span>或 &nbsp;拨打</span> 400 061 8800
        </div>

        <div class="link">
          <el-link type="primary" href="https://www.teddymobile.cn/numberComplain" target="_blank"
            style="font-size: 18px">泰迪熊移动</el-link>
          <span>或 &nbsp;拨打</span> 400-825-3666
        </div>

        <div class="link">
          <el-link type="primary" href="https://yun.m.qq.com/content.html#1" target="_blank"
            style="font-size: 18px">腾讯手机管家</el-link>
          <span>或 &nbsp;拨打</span> 4006-700-700
        </div>

        <div class="link">
          <el-link type="primary" href="https://haoma.baidu.com/person/complaint" target="_blank"
            style="font-size: 18px">百度手机卫士</el-link>
          <span>或 &nbsp;拨打</span> 400-921-3900
        </div>

        <div class="text">
          <p style="font-weight:bold">阿里钱盾</p>
          <p>钱盾号码标记申诉渠道现改为手机淘宝安全中心，具体操作流程如下：</p>
          <div>
            <div class="process-box" v-for="v in processArr">
              <div class="num">第{{ v.num }}步</div>
              <div class="content">{{ v.text }}</div>
            </div>
          </div>
        </div>

        <div class="text">
          <p>
            搜狗号码通：发送邮件到：<em>haomatong_service@sogou-inc.com</em>（写明需求）
          </p>
        </div>
      </div>
    </main>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      processArr: [{
        num: 1,
        text: '打开手机淘宝客户端（需要将手机淘宝客户端更新至最新版本）'
      },
      {
        num: 2,
        text: '点击右下角我的淘宝'
      }, {
        num: 3,
        text: '点击右上角设置'
      }, {
        num: 4,
        text: '点击账户与安全'
      }, {
        num: 5,
        text: '点击最下方的安全中心（蓝色字体）'
      }, {
        num: 6,
        text: '点击中间靠右侧的号码申诉'
      }, {
        num: 7,
        text: '按提示填写相关内容后点击提交即可'
      }]
    }
  }
};
</script>

<style lang="less" scoped>
#unmark {
  height: 100%;
  overflow-y: scroll;
  header {
    h1 {
      font-weight: normal;
      text-align: center;
    }
  }

  main {
    text-align: center;

    .link {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      span {
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .text {
      display: flex;
      align-items: center;
      flex-direction: column;

      p {
        font-size: 18px;
        margin-bottom: 20px;
      }

      .process-box {
        width: 100%;
        font-size: 18px;
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content {
          flex: 1;
        }

        .num {
          font-weight: bold;
          width: 80px;
        }
      }
    }
  }
}</style>
